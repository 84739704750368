<template>
  <ion-page>
    <ion-header class="header" mode="ios">
      <div class="icon-scroll" />
      <ion-toolbar class="pb-1 pr-2">
        <ion-title class="pl-3 ion-text-start" color="text-title">{{
          $t('request_quotation_details')
        }}</ion-title>
        <ion-icon slot="end" :icon="closeOutline" @click="dismissModal" />
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <container paddingHorizontal class="pt-5">
        <user-item :name="getUserName" :address="item.customer_name" :image="item.requestor.image" />
      </container>
      <container paddingHorizontal paddingVertical>
        <request-item :date="item.created_at" :quotationStatus="item.quotation_status" />
      </container>
      <container paddingHorizontal class="contain">
        <product-item :item="item" :key="item.id" />
        <div class="mt-2" v-if="item.direct_price">
          <div>{{ $t('support_information') }}</div>
          <div class="mt-2 mb-2">{{ item.description || '-' }}</div>
        </div>
      </container>
      <container paddingHorizontal class="contain">
        <ion-grid>
          <ion-row>
            <ion-col size="4" class="ion-text-uppercase">
              <ion-text color="text-medium">
                <h6>
                  {{ $t('direct_price') }}
                </h6>
              </ion-text>
            </ion-col>
            <ion-col size="4" class="ion-text-end">
              <ion-text color="text-primary">
                <p>
                  {{
                    item.direct_price == 0
                      ? '-'
                      : addCurrencySymbol(currencySymbol, displayPrice(item.direct_price))
                  }}
                </p>
              </ion-text>
            </ion-col>
            <ion-col size="4" class="ion-text-end">{{
              item.direct_price === 0
                ? '-'
                : `(${addCurrencySymbol(
                    currencySymbol,
                    displayPrice(calculatePriceOnWeight(item.direct_price, item.weight))
                  )}/${UNIT_DEFAULT})`
            }}</ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="4" class="ion-text-uppercase">
              <ion-text color="text-medium">
                <h6>{{ $t('limit_price') }}</h6>
              </ion-text>
            </ion-col>
            <ion-col size="4" class="ion-text-end" color="text-primary">
              <ion-text color="text-primary">
                <p>
                  {{
                    item.limit_price == 0
                      ? '-'
                      : `${priceFormatter(currencySymbol, displayPrice(item.limit_price))}`
                  }}
                </p>
              </ion-text>
            </ion-col>
            <ion-col size="4" class="ion-text-end">{{
              item.limit_price == 0
                ? '-'
                : `(${priceFormatter(
                    currencySymbol,
                    displayPrice(calculatePriceOnWeight(item.limit_price, item.weight))
                  )}/${UNIT_DEFAULT})`
            }}</ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="7" class="ion-text-uppercase">
              <h6>{{ $t('total_new_price') }}</h6>
            </ion-col>
            <ion-col size="5" class="ion-text-end">
              <ion-text :class="computedRef.isRejected ? 'text-gray-500' : 'text-warning-500'">
                <h5>
                  {{
                    computedRef.isRejected
                      ? currencySymbol + '-'
                      : priceFormatter(currencySymbol, displayPrice(price))
                  }}
                </h5>
              </ion-text>
            </ion-col>
          </ion-row>
        </ion-grid>
      </container>
      <container paddingHorizontal>
        <ion-grid>
          <ion-row>
            <ion-text>
              <h5>{{ $t('set_new_price') }}</h5>
            </ion-text>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-item>
                <ion-label>{{ currencySymbol }}</ion-label>
                <ion-input
                  class="ion-text-right border-bottom-input"
                  :placeholder="computedRef.isRejected ? '-' : '0.00'"
                  type="number"
                  inputmode="decimal"
                  :disabled="computedRef.isDisableBtn"
                  v-model="totalNewPrice"
                  @ionChange="changePrice"
                />
              </ion-item>
            </ion-col>
            <ion-col size="6" class="ml-2 d-flex align-center">
              <ion-text class="mx-2">{{ $t('per') }}</ion-text>
              <ion-radio-group value="kg" mode="md" class="mx-2">
                <ion-radio value="kg" class="mr-1" />
                <ion-text>{{ UNIT_DEFAULT }}</ion-text>
                <!-- TODO this is future feature. UNIT will depended on OOM -->
                <!-- <ion-radio></ion-radio>
                <ion-text>{{ $t('per_unit') }}</ion-text> -->
              </ion-radio-group>
            </ion-col>
          </ion-row>
        </ion-grid>
      </container>
    </ion-content>
    <ion-footer class="ion-no-border">
      <ion-grid>
        <ion-row>
          <ion-col>
            <custom-button
              v-if="!computedRef.isDisableBtn"
              :disabled="isDisabledSubmit || computedRef.isDisableBtn"
              :class="color"
              expand="block"
              mode="ios"
              @click="onApprovePrice"
            >
              <ion-text class="ion-text-capitalize">
                {{ $t(text) }}
              </ion-text>
            </custom-button>
            <ion-button
              v-if="item.direct_price > 0 && item.quotation_status === 'requested'"
              size="small"
              expand="block"
              class="ml-2"
              fill="clear"
              color="danger"
              @click="clickRejectQuotation"
            >
              <ion-label>{{ $t('reject') }} </ion-label>
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-footer>
  </ion-page>
</template>
<script>
import CustomButton from '@/components/atoms/button/Button.vue';
import Container from '@/components/atoms/container/Container.vue';
import UserItem from '@/components/molecules/user-item/UserItem.vue';
import ProductItem from '@/components/product-item/ProductItem.vue';
import RequestItem from '@/components/request-item/RequestItem.vue';
import { displayPrice } from '@/modules/sale/services/libs/helper';
import { UNIT_DEFAULT } from '@/modules/shared/constants/';
import { priceFormatter } from '@/utils/';
import { alertController } from '@ionic/vue';
import { closeOutline, homeOutline } from 'ionicons/icons';
import { computed, defineComponent, reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import {
  addCurrencySymbol,
  calculatePriceOnWeight,
  useChangePrice,
  useCheckElement,
  useLoadData,
  useSendRejectQuotation,
  useSendUpdatePrice
} from './composable';

export default defineComponent({
  components: {
    RequestItem,
    ProductItem,
    CustomButton,
    Container,
    UserItem
  },
  props: {
    id: {
      type: Number,
      required: true
    },
    dismissModal: {
      required: true
    }
  },
  setup(props) {
    const { t } = useI18n();
    const totalNewPrice = ref();
    const { item, getUserName, isCallFinish } = useLoadData(props.id);
    const { unitIsNull } = useCheckElement();
    const { updatePrice, unitPrice, price, color, text, isDisabledSubmit } = useChangePrice();
    const { submitUpdatePrice } = useSendUpdatePrice();
    const { submitRejectQuotation } = useSendRejectQuotation();
    const changePrice = (event) => {
      const newPriceValue = {
        value: event.target.value,
        weight: item.value.weight,
        limitPrice: item.value.limit_price
      };
      let pattern = /(\d+\.\d\d)/g;
      let maxLength = /(\d{0,18})/g.exec(newPriceValue.value);
      if (maxLength.input.length > 18) {
        event.target.value = maxLength[1];
      }
      let regex = pattern.exec(newPriceValue.value);
      if (regex) {
        event.target.value = parseFloat(regex[1]);
      }
      updatePrice(newPriceValue);
    };
    const computedRef = reactive({
      isPendingAndApproval: computed(() => {
        return (
          item.value.quotation_status === 'pending approval' || item.value.quotation_status === 'received'
        );
      }),
      isRejected: computed(() => {
        return item.value.quotation_status === 'rejected';
      }),
      isDisableBtn: computed(() => {
        const data = item.value.quotation_status;
        return data === 'pending approval' || data === 'received' || data === 'rejected';
      })
    });

    watch(isCallFinish, () => {
      if (isCallFinish.value) {
        setTimeout(() => {
          if (computedRef.isPendingAndApproval) {
            totalNewPrice.value = displayPrice(item.value.price / item.value.weight);
          }
        }, 100);
      }
    });

    const onApprovePrice = async () => {
      if (item.value.direct_price > 0 && item.value.direct_price < price.value) {
        const alert = await alertController.create({
          mode: 'ios',
          header: t('quote_price_great_direct_price_title'),
          message: t('quote_price_great_direct_price_body'),
          backdropDismiss: false,
          buttons: [
            {
              text: t('cancel')
            },
            {
              text: t('quote_price_great_direct_price_agree'),
              handler: () => {
                submitSetPrice();
              }
            }
          ]
        });
        return alert.present();
      } else {
        submitSetPrice();
      }
    };

    const submitSetPrice = () => {
      const value = {
        id: props.id,
        price: price.value,
        limitPrice: item.value.limit_price,
        unitPrice: unitPrice.value
      };
      return submitUpdatePrice(value, props.dismissModal);
    };

    const clickRejectQuotation = () => {
      const payload = {
        id: props.id
      };
      return submitRejectQuotation(payload, props.dismissModal);
    };

    return {
      calculatePriceOnWeight,
      priceFormatter,
      isDisabledSubmit,
      color,
      text,
      item,
      getUserName,
      UNIT_DEFAULT,
      unitIsNull,
      closeOutline,
      homeOutline,
      displayPrice,
      changePrice,
      price,
      submitSetPrice,
      computedRef,
      totalNewPrice,
      isCallFinish,
      addCurrencySymbol,
      clickRejectQuotation,
      onApprovePrice
    };
  },
  data() {
    return {
      user: null
    };
  },
  inject: ['$storage'],
  async mounted() {
    this.user = await this.$storage.getUser();
  },
  computed: {
    currencySymbol() {
      return this.user ? this.user.country.currency_symbol : '';
    }
  }
});
</script>
<style lang="scss" scoped>
.header {
  ion-icon {
    color: black;
    font-size: 20px;
    background-color: #989aa226;
    padding: 5px;
    border-radius: 20px;
  }

  ion-title {
    font-size: 20px;
    font-weight: 500;
  }

  ion-toolbar {
    border-bottom: 0.5px solid #9794941a;
  }
}

.contain {
  border-bottom: 5px solid var(--ion-color-light);
}

ion-item {
  --padding-end: 0px;
  --inner-padding-end: 0px;
  --border-color: transparent;
}

ion-text {
  font-size: 16px;
}

h5,
h6,
p {
  margin: 0px;
}

h5 {
  font-size: 16px;
  font-weight: 600;
}

h6 {
  font-size: 12px;
  font-weight: 700;
}

p {
  font-size: 16px;
}

.button-warning {
  --background: var(--ion-color-button-warning);
  color: white;
}

.button-primary {
  --background: var(--ion-color-button-primary);
  color: white;
}

ion-radio-group {
  overflow: visible;
  white-space: nowrap;
}
</style>
