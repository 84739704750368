<template>
  <div class="d-flex flex-row justify-space-between align-center">
    <div class="d-flex flex-column">
      <ion-text
        ><h6>{{ $t('request_at') }}</h6></ion-text
      >
      <ion-text class="ion-no-padding fs-12">{{ dayjs(date).format('DD MMM YYYY HH:mm') }}</ion-text>
    </div>
    <ion-button :color="color" class="button d-flex align-center">
      <span class="ion-text-capitalize mr-1">{{ quotationStatus }} </span>
      <ion-icon :icon="icon" />
    </ion-button>
  </div>
</template>
<script>
import { QUOTATION_STATUS_ICON } from '@/modules/shared/constants/';
import dayjs from 'dayjs';
import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    date: {
      type: String,
      default: ''
    },
    quotationStatus: {
      type: String,
      default: QUOTATION_STATUS_ICON['requested']
    }
  },
  setup(props) {
    const getKeyQuotationStatus = Object.keys(QUOTATION_STATUS_ICON).find((key) =>
      props.quotationStatus.toLowerCase().includes(key)
    );
    const { color, icon } = QUOTATION_STATUS_ICON[getKeyQuotationStatus || 'requested'];
    return {
      color,
      icon,
      dayjs
    };
  }
});
</script>
<style lang="scss" scoped>
.button {
  padding: 5px 0px;
  font-size: 12px;
  --border-radius: 100px;
}
h6 {
  margin: 0px;
}
</style>
